<template>
	<v-dialog
		v-model="dialog" 
		max-width="400px"
		class="rounded-xl"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-card
				flat
				:ripple="false"
				v-bind="attrs" 
				v-on="on"
				:disabled="dialogDisable(reward)" 
				class="mgn_card rounded-xl d-flex flex-column align-center justify-space-around text-center rewards_card"
			>
				<v-img v-if="reward.urls.length > 0" :src="reward.urls[0]" height="140" transition="fade-transition"></v-img>
				<v-card-title class="text-h6">{{ reward.title }}</v-card-title>

				<v-card-text v-if="reward.category.category && reward.category.server_host">
					FiveM Server: {{ reward.category.server_host }}
				</v-card-text>
				
				<v-card-actions class="mgn_card_actions d-flex flex-column mt-3 mb-5">
					<v-chip class="mgn_chip justify-center my-1" v-for="shop in reward.points_requirements" :key="shop.streamer_shop" v-show="shop.points > 0">
						{{ shop.points }} {{ shop.shorthand }} points
					</v-chip>
				</v-card-actions>
			</v-card>
		</template>

		<v-form ref="form" @submit.prevent="RedeemReward(reward.id)" lazy-validation>
			<v-card class="d-flex flex-column align-center justify-space-between">
				<v-carousel v-model="carousel" cycle hide-delimiters height="250">
					<v-carousel-item v-for="(img) in reward.urls" :key="img">
						<v-img :src="img" height="250px"></v-img>
					</v-carousel-item>
				</v-carousel>

				<v-card-title>
					<span class="text-h5">{{ reward.title }}</span>
				</v-card-title>

				<v-card-text v-html="reward.description"></v-card-text>

				<v-chip class="mgn_chip justify-center my-1" v-for="shop in reward.points_requirements" :key="shop.streamer_shop" v-show="shop.points > 0">
					{{ shop.points * multi_redeem_counter }} {{ shop.shorthand }} points
				</v-chip>

				<v-card-actions class="mt-10">
					<v-container>
						<v-row>
							<v-col class="d-flex justify-center align-center" cols="12" v-if="reward.has_multiple_redeems">
								<v-btn color="primary" icon @click="Dec" :disabled="!UserPointsRequirements()">
									<v-icon>mdi-minus</v-icon>
								</v-btn>
								<h3 class="mx-2">{{ multi_redeem_counter }}</h3>
								<v-btn color="primary" icon @click="Inc" :disabled="!UserPointsRequirements()">
									<v-icon>mdi-plus</v-icon>
								</v-btn>
							</v-col>
							<v-col cols="12" class="d-flex justify-center align-center">
								<v-tooltip bottom :disabled="UserPointsRequirements()">
									<template v-slot:activator="{ on }">
										<div v-on="on">
											<v-btn 
												color="primary" 
												type="submit" 
												:disabled="!UserPointsRequirements() || loadingRedeemBtn"
												:loading="loadingRedeemBtn"
											>Redeem now</v-btn>
										</div>
									</template>
									<span>You need all the required points to redeem this reward!</span>
								</v-tooltip>
							</v-col>
						</v-row>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script>
export default {
	props: ['reward'],

	data() {
		return {
			carousel: 0,
			multi_redeem_counter: 1,
			loadingRedeemBtn: false,
			dialog: false,
		}
	},

	methods: {
		sumPoints(points) {
			return points * this.multi_redeem_counter; 
		},

		Inc() {
			this.multi_redeem_counter++
		},

		Dec() {
			if (this.multi_redeem_counter > 1) this.multi_redeem_counter--;
		},

		UserClaimedReward(reward_id){
			return this.$store.state.user.redeems.some(redeem => redeem.id === reward_id);
		},

		dialogDisable(reward) {
			if (!reward.repeatable && this.UserClaimedReward(reward.id)) {
				return true;
			}

			if (reward.has_max_redeems && reward.max_redeems >= reward.redeemers.length) {
				return true;
			}

			if (reward.steam_auth_required && this.$store.state.user.steam_id === null) {
				return true;
			}
			
			return false;
		},

		UserPointsRequirements() {
			let l = 0;
			let reward_points = this.reward.points_requirements.filter(req => req.points > 0);

			reward_points.forEach(requirement => {
				if (this.$store.state.user.aggregated_points_status) {
					this.$store.state.user.aggregated_points.forEach(user => {
						if ((user.id === requirement.id || user.id === requirement.twitch_id) && user.points >= requirement.points) l++;
					});
				}
				else {
					this.$store.state.user.points.forEach(user => {
						if ((user.id === requirement.id || user.id === requirement.twitch_id) && user.points >= requirement.points) l++;
					});
				}
			});
			
			return l === reward_points.length;
		},

		async RedeemReward(reward_id) {
			let body = {};

			if (this.reward.has_multiple_redeems) {
				body = JSON.stringify({
					id: reward_id,
					multi: this.multi_redeem_counter
				});
			}
			else {
				body = JSON.stringify({
					id: reward_id
				});
			}

			await this.SendRequest(body);
		},

		async SendRequest(body) {
			this.loadingRedeemBtn = true;

			try {
				const response = await fetch(this.$store.state.base_url + '/user/redeem', {
					method: 'POST',
					body: body,
					headers: {
						"Content-type" : "application/json; charset=UTF-8",
						"Authorization" : this.$cookies.get('guardian_key_session'),
					}
				});

				const res = await response.json();
				
				if (response.ok) {
					if (res.error) {
						if (res.status === 1003 || res.status === 1001 || res.status === 1000) {
							this.sessionExpiredHandler(res);
						}
					}
					
					if (res.isRewardRedeemed) {
						this.$notify({
							group: 'main',
							type: 'success',
							title: this.$store.state.app_name,
							text: 'Reward redeemed successfully!'
						});

						this.dialog = false;

						await this.$store.dispatch('fetchUserInfo');
					}
					else if (res.rewardAlreadyClaimed) {
						this.$notify({
							group: 'main',
							type: 'warning',
							title: this.$store.state.app_name,
							text: 'Reward already claimed.'
						});

						this.dialog = false;
					}
					else if (res.onCooldown) {
						let hours = Math.floor(res.diff / 3600);
						let minutes = Math.floor(res.diff % 3600 / 60);
						let seconds = res.diff % 60;

						let h = hours > 0 ? hours + ' hours ' : ''
						let m = minutes > 0 ? minutes + ' minutes ' : ''
						let s = seconds > 0 ? seconds + ' seconds' : ''
						
						this.$notify({
							group: 'main',
							type: 'error',
							title: this.$store.state.app_name,
							text: 'Reward on cooldown. You can redeem again in ' + h + m + s
						});

						this.dialog = false;
					}
					else if (res.error) {
						this.$notify({
							group: 'main',
							type: 'error',
							title: this.$store.state.app_name,
							text: res.error
						});
					}
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			}
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error
				});
			}
			finally {
				this.multi_redeem_counter = 1;
				this.loadingRedeemBtn = false;
			}
		}
	},
}
</script>

<style scoped>
.mgn_card_actions {
	min-height: 100px !important;
}
</style>