<template>
	<div id="particles-js" class="particles"></div>
</template>

<script>
import 'particles.js';

export default {
	mounted() {
		window.particlesJS("particles-js", {
			"particles": {
			"number": {
				"value": 250,
				"density": {
				"enable": true,
				"value_area": 1000
				}
			},
			"color": {
				"value": "#3FBAC2"
			},
			"shape": {
				"type": "circle",
				"stroke": {
				"width": 0,
				"color": "#000000"
				},
				"polygon": {
				"nb_sides": 4
				},
				"image": {
				"src": "img/github.svg",
				"width": 100,
				"height": 100
				}
			},
			"opacity": {
				"value": 1,
				"random": false,
				"anim": {
				"enable": true,
				"speed": 1,
				"opacity_min": 0.1,
				"sync": false
				}
			},
			"size": {
				"value": 2,
				"random": true,
				"anim": {
				"enable": false,
				"speed": 40,
				"size_min": 0.1,
				"sync": false
				}
			},
			"line_linked": {
				"enable": false,
				"distance": 299.87610382026367,
				"color": "#3FBAC2",
				"opacity": 0.2683101981549727,
				"width": 1.3
			},
			"move": {
				"enable": true,
				"speed": 2,
				"direction": "right",
				"random": true,
				"straight": false,
				"out_mode": "out",
				"bounce": false,
				"attract": {
				"enable": false,
				"rotateX": 600,
				"rotateY": 1200
				}
			}
			},
			"interactivity": {
			"detect_on": "canvas",
			"events": {
				"onhover": {
				"enable": false,
				"mode": "repulse"
				},
				"onclick": {
				"enable": true,
				"mode": "push"
				},
				"resize": true
			},
			"modes": {
				"grab": {
				"distance": 400,
				"line_linked": {
					"opacity": 1
				}
				},
				"bubble": {
				"distance": 400,
				"size": 40,
				"duration": 2,
				"opacity": 8,
				"speed": 3
				},
				"repulse": {
				"distance": 200,
				"duration": 0.4
				},
				"push": {
				"particles_nb": 4
				},
				"remove": {
				"particles_nb": 2
				}
			}
			},
			"retina_detect": true
		});
	},
}
</script>

<style scoped>
.particles {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}
</style>